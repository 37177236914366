.VCard {
  .contact-download {
    background: #4c443a;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    display: inline-block;
    color: #fff;
    text-shadow: -1px -1px rgba(0, 0, 0, 0.195), 1px 1px rgba(255, 255, 255, 0.267);
    text-decoration: none;
    box-shadow: 1px 1px 2px rgba(54, 54, 54, 0.425);
    margin-top: 1.5rem;    
    transition-duration: 200ms;
    
    &:hover {
      background: #2f2a23;
    }
  }
  
  @media only screen and (min-width : 1025px) {
    .contact-download {
      margin-bottom: 1rem;
    }
  }
}
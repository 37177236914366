$brownLight: #cdbfb0;
$brownMid: #7a6b5a;
$brownDark: #4c443a;
$textColorDefault: #3b3b3b;
$backgroundImg: url('../img/backgrounds/retina-dust.png');

* {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  text-align: center;
  font-family: 'Gruppo', cursive;
  background-color: $brownLight;
  color: $textColorDefault;
  height: 100vh;
  // min-height: 100vh;
  height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  background-image: $backgroundImg;

  // box-shadow: inset 0px 5px 5px 0px rgba($brownDark, 0.125);

  @media only screen and (min-width: 480px) {
    min-height: 100vh;
  }
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

footer {
  picture {
    max-width: 40rem;
    display: block;
    margin: auto;
  }
}

h1, h2, h3, h4 {
  font-weight: 200;
  font-family: 'Londrina Solid', cursive;
  small {
    display: block;
    font-size: 0.9rem;
  }
}

h1 {
  font-size: 55px;
  margin-block-end: 1rem;
  text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.125), 1px 1px 1px rgba(256, 256, 256, 0.125);
}

main {
  overflow: visible;
  position: relative;
  flex: 1;
}

aside {
  overflow: hidden;

  img {
    max-width: 80%;
    position: relative;
    bottom: -2rem;
  }
}

p {
  line-height: 1.5em;
}

a {
  color: dodgerblue;
}

ul.links {
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    margin: auto;
    display: inline-block;

    a {
      display: block;
      color: $brownDark;
      text-decoration: none;
      position: relative;
      overflow: hidden;
    }
  }

  &.links--social {
    .link-content {
      display: none;
      visibility: hidden;
    }
  }

  &.links--contact {
    li {
      display: block;
      font-size: 1.2rem;

      a {
        display: flex;
        align-content: center;
        justify-content: center;
        padding-bottom: 0.5rem;
        span {
          display: block;
          line-height: 1.5rem;
          padding-left: 0.25rem;
        }
      }
    }
  }
}

.icon {
  fill: $brownDark;
}

ul.links--social {
  padding-top: 1rem;
  padding-bottom: 0.25rem;
  .icon {
    height: 4rem;
    width: 4rem;
  }
}


.intro {
  margin: 0 2.125rem 0.25rem  2.125rem;
  padding: 0;
  font-size: 1.2rem;
}
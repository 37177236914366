* {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  text-align: center;
  font-family: "Gruppo", cursive;
  background-color: #cdbfb0;
  color: #3b3b3b;
  height: 100vh;
  height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  background-image: url("retina-dust.799ab788.png");
}

@media only screen and (min-width: 480px) {
  body {
    min-height: 100vh;
  }
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

footer picture {
  max-width: 40rem;
  display: block;
  margin: auto;
}

h1, h2, h3, h4 {
  font-weight: 200;
  font-family: "Londrina Solid", cursive;
}

h1 small, h2 small, h3 small, h4 small {
  display: block;
  font-size: 0.9rem;
}

h1 {
  font-size: 55px;
  margin-block-end: 1rem;
  text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.125), 1px 1px 1px rgba(255, 255, 255, 0.125);
}

main {
  overflow: visible;
  position: relative;
  flex: 1;
}

aside {
  overflow: hidden;
}

aside img {
  max-width: 80%;
  position: relative;
  bottom: -2rem;
}

p {
  line-height: 1.5em;
}

a {
  color: dodgerblue;
}

ul.links {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

ul.links li {
  margin: auto;
  display: inline-block;
}

ul.links li a {
  display: block;
  color: #4c443a;
  text-decoration: none;
  position: relative;
  overflow: hidden;
}

ul.links.links--social .link-content {
  display: none;
  visibility: hidden;
}

ul.links.links--contact li {
  display: block;
  font-size: 1.2rem;
}

ul.links.links--contact li a {
  display: flex;
  align-content: center;
  justify-content: center;
  padding-bottom: 0.5rem;
}

ul.links.links--contact li a span {
  display: block;
  line-height: 1.5rem;
  padding-left: 0.25rem;
}

.icon {
  fill: #4c443a;
}

ul.links--social {
  padding-top: 1rem;
  padding-bottom: 0.25rem;
}

ul.links--social .icon {
  height: 4rem;
  width: 4rem;
}

.intro {
  margin: 0 2.125rem 0.25rem 2.125rem;
  padding: 0;
  font-size: 1.2rem;
}
.VCard .contact-download {
  background: #4c443a;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  display: inline-block;
  color: #fff;
  text-shadow: -1px -1px rgba(0, 0, 0, 0.195), 1px 1px rgba(255, 255, 255, 0.267);
  text-decoration: none;
  box-shadow: 1px 1px 2px rgba(54, 54, 54, 0.425);
  margin-top: 1.5rem;
  transition-duration: 200ms;
}
.VCard .contact-download:hover {
  background: #2f2a23;
}
@media only screen and (min-width: 1025px) {
  .VCard .contact-download {
    margin-bottom: 1rem;
  }
}
/*# sourceMappingURL=index.39e817d7.css.map */
